import {Controller} from "@hotwired/stimulus"

export class FeedbackController extends Controller {
    static targets = [
        "alertReceivedAtWrapper",
    ]

    toggleVisibility(e) {
        if (e.target.value === "true") {
            this.alertReceivedAtWrapperTarget.classList.remove("d-none")
        } else {
            this.alertReceivedAtWrapperTarget.classList.add("d-none")
        }
    }
}