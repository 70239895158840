import {MapController} from "./map_controller";

export class FeedbackMapController extends MapController {
    static targets = [
        "container",
        "latitudeInput",
        "longitudeInput",
    ]

    INITIAL_ZOOM = 7.5

    async connect() {
        super.connect()

        this.map.on('click', (e) => {
            this.moveOrAddMarker(e.latlng)
        });

        this.findMe()
    }

    findMe() {
        this.map.locate({setView: true, maxZoom: 16})
            .on('locationfound', (e) => {
                this.moveOrAddMarker(e.latlng)
            });
    }

    moveOrAddMarker(latlng) {
        this.latitudeInputTarget.value  = latlng.lat
        this.longitudeInputTarget.value = latlng.lng
        if (this.marker) {
            this.marker.setLatLng(latlng)
        } else {
            this.marker = L.marker(latlng, {draggable: true, autoPan: true}).addTo(this.map).on('dragend', (e) => {
                let latlng                      = e.target.getLatLng()
                this.latitudeInputTarget.value  = latlng.lat
                this.longitudeInputTarget.value = latlng.lng
            })
        }
    }
}