import {config, dom, library} from '@fortawesome/fontawesome-svg-core'
import {faInfoCircle, faMapLocationDot, faPersonShelter} from '@fortawesome/free-solid-svg-icons'

config.mutateApproach = 'sync';

library.add(
    faMapLocationDot,
    faPersonShelter,
    faInfoCircle
)

dom.watch();
