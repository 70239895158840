import {Controller} from "@hotwired/stimulus"

export class RefreshController extends Controller {
    connect() {
        this.interval = setInterval(() => {
            Turbo.visit(window.location.href, {action: "replace"})
        }, 30000);
    }

    disconnect() {
        clearInterval(this.interval);
    }
}