import {Controller} from "@hotwired/stimulus"

const AVAILABLE_LOCALES = ["de", "en"]

export class I18nController extends Controller {
    static targets = AVAILABLE_LOCALES

    connect() {
        this.currentLocal = localStorage.getItem("locale") || "de"
        this.#show(this.currentLocal)
        document.addEventListener("turbo:morph", () => {
            this.#show(this.currentLocal)
        })
    }

    switch(event) {
        event.preventDefault()
        let local = event.target.textContent?.toLowerCase()
        if (!local || !AVAILABLE_LOCALES.includes(local)) {
            local = "de"
        }
        this.currentLocal = local
        this.#show(this.currentLocal)
        localStorage.setItem("locale", this.currentLocal)
    }

    #show(local) {
        AVAILABLE_LOCALES.forEach((l) => {
            if (local === l) {
                return
            }
            this[l + "Targets"].forEach((element) => {
                element.classList.add("d-none")
            })
        })
        this[local + "Targets"].forEach((element) => {
            element.classList.remove("d-none")
        })
        document.querySelector("html").lang = local
    }
}