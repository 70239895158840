import {Controller} from "@hotwired/stimulus"
import L from "leaflet";
import {get} from "@rails/request.js";

export class MapController extends Controller {
    static targets = [
        "container",
    ]

    static values = {
        areas: Array,
        districtBoundaries: String,
        cadastralBoundaries: String,
    }

    MAP_CENTER   = [47.483716, 16.26888]
    INITIAL_ZOOM = 9.5

    ZOOM_LEVEL_FOR_CADASTRAL = 11;

    async connect() {
        // initialize the map on the "map" div with a given center and zoom
        this.map = L.map(this.containerTarget, {
            zoomSnap: 0.5,
            zoomControl: false,
            dragging: !L.Browser.mobile,
            tap: !L.Browser.mobile
        });

        this.map.on("load", () => {
            this.loaded = true
            this.map.invalidateSize()
            this.#updatePolygons()
            this.resizeInterval = setInterval(() => {
                if (this.map.getSize().y >= 400) {
                    this.#updatePolygons()
                    clearInterval(this.resizeInterval)
                } else {
                    this.map.invalidateSize()
                }
            }, 100)
        })

        this.map.setView(this.MAP_CENTER, this.INITIAL_ZOOM)

        L.control.zoom({
            position: "topright"
        }).addTo(this.map);

        this.#setupBaseMaps()
        await this.#setupBoundaries()
        this.#setupZoomCallbacks()
    }

    areasValueChanged() {
        if (this.map && this.loaded) {
            this.#updatePolygons()
        }
    }

    async getGeoJson(url) {
        let response = await get(url, {
            responseKind: "json",
        })

        if (response.ok) {
            return await response.json
        }
    }

    createPolygon(jsonCoordinates) {
        let coordinates = jsonCoordinates.map((coordinate) => [coordinate["lat"], coordinate["lng"]])
        this.polyLayer.addLayer(L.polygon(coordinates, {color: 'red'}))
    }

    disconnect() {
        this.map.remove()
    }

    #setupBaseMaps() {
        const basemap = L.tileLayer("https://mapsneu.wien.gv.at/basemap/geolandbasemap/{type}/google3857/{z}/{y}/{x}.{format}", {
            maxZoom: 21,
            maxNativeZoom: 19,
            attribution: 'Datenquelle: <a href="https://www.basemap.at">basemap.at</a>',
            type: "normal",
            format: "png",
            bounds: [[46.35877, 8.782379], [49.037872, 17.189532]]
        }).addTo(this.map)

        const basemapOrtho = L.tileLayer("https://mapsneu.wien.gv.at/basemap/bmaporthofoto30cm/{type}/google3857/{z}/{y}/{x}.{format}", {
            maxZoom: 21,
            maxNativeZoom: 19,
            attribution: 'Datenquelle: <a href="https://www.basemap.at">basemap.at</a>',
            type: "normal",
            format: "jpeg",
            bounds: [[46.35877, 8.782379], [49.037872, 17.189532]]
        })

        const baseMaps = {
            "Basemap.at": basemap,
            "Basemap.at Orthofoto": basemapOrtho,
        };

        L.control.layers(baseMaps).addTo(this.map);
    }

    async #setupBoundaries() {
        const districtBoundaries  = await this.getGeoJson(this.districtBoundariesValue)
        const cadastralBoundaries = await this.getGeoJson(this.cadastralBoundariesValue)

        const boundaryStyle = {
            "fillColor": "none", "color": "#000", "weight": 1, "opacity": 0.7,
        };

        this.districtBoundariesLayer  = L.geoJSON(districtBoundaries, {style: boundaryStyle}).addTo(this.map)
        this.cadastralBoundariesLayer = L.geoJSON(cadastralBoundaries, {style: boundaryStyle})
    }

    #setupZoomCallbacks() {
        this.map.on("zoomend", (e) => {
            const zoom = this.map.getZoom()
            if (zoom > this.ZOOM_LEVEL_FOR_CADASTRAL) {
                if (!this.show_cadastral) {
                    this.show_cadastral = true;
                    this.districtBoundariesLayer.remove()
                    this.cadastralBoundariesLayer.addTo(this.map)
                }
            } else {
                if (this.show_cadastral) {
                    this.show_cadastral = false;
                    this.districtBoundariesLayer.addTo(this.map)
                    this.cadastralBoundariesLayer.remove()
                }
            }
        })
    }

    #updatePolygons() {
        if (!this.polyLayer)
            this.polyLayer = L.featureGroup().addTo(this.map);

        this.polyLayer.clearLayers().clearLayers()

        this.areasValue.forEach((area) => {
            let coordinates = JSON.parse(area.coordinates)
            if (!Array.isArray(coordinates))
                coordinates = [coordinates]
            coordinates.forEach((coordinate) => {
                this.createPolygon(coordinate)
            })
        })


        const bounds = this.polyLayer.getBounds()
        if (bounds.isValid()) {
            this.map.fitBounds(bounds, {maxZoom: this.INITIAL_ZOOM})
        } else {
            this.map.panTo(this.MAP_CENTER)
        }
    }
}